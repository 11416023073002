import * as React from "react"
import { FC, useState, useEffect } from "react"
import { animated, useTransition, config } from "react-spring"
import { Image } from "theme-ui"

export interface IPropsImageFade {
  images: []
  pause?: boolean
}

const AnimatedImage = animated(Image)

export const ImageFade: FC<IPropsImageFade> = ({ pause, images }) => {
  const [index, set] = useState<number>(0)

  const transition = useTransition(images[index], {
    key: (item) => item?.node?.id,
    from: {
      opacity: 0,
      scale: 0.9,
    },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 1.1 },
    config: config.molasses,
  })

  useEffect(() => {
    const timeout = setInterval(() => {
      if (!pause) set((state) => (state + 1) % images.length)
    }, 5000)
    return () => {
      clearInterval(timeout)
    }
  }, [pause])

  return transition(({ opacity, scale }, item) => {
    const { src } = item?.node?.original
    return (
      <AnimatedImage
        src={src}
        alt=""
        style={{
          opacity,
          transform: scale.to((value: number) => `scale(${value})`),
        }}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          willChange: "opacity",
        }}
      />
    )
  })
}
