/** @jsx jsx */
import { FC, ReactNode } from "react"
import { jsx, Button as ThemeUiButton, Box, Text } from "theme-ui"

import { IconArrowStickRight } from "icons"

export interface IPropsButton {
  children: ReactNode
  className?: string
}

export const Button: FC<IPropsButton> = ({ children, className }) => {
  return (
    <ThemeUiButton
      className={className}
      sx={{
        ["--height"]: ["48px", "56px", "48px", "56px"],
        ["--durationIn"]: "0.25s",
        ["--durationOut"]: "0.5s",
        border: 0,
        appearance: "none",
        outline: "none",
        textDecoration: "none",
        cursor: "pointer",
        position: "relative",
        bg: "transparent",
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "inline-grid",
          gridTemplateColumns: "var(--height) min-content",
          height: "var(--height)",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Box
          as="span"
          sx={{
            width: "var(--height)",
            height: "var(--height)",
            borderRadius: "calc(var(--height) / 2)",
            bg: "backgroundSecondary",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            transition: "width var(--durationOut) var(--animation-bezier)",

            "button:hover &": {
              width: "100%",
              transitionDuration: "var(--durationIn)",
            },
          }}
        />
        <Box
          as="span"
          sx={{
            gridColumn: "1/2",
            width: "var(--height)",
            height: "var(--height)",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            transition: "transform var(--durationOut) var(--animation-bezier)",

            "button:hover &": {
              transform: "translateX(16px)",
              transitionDuration: "var(--durationIn)",
            },
          }}
        >
          <IconArrowStickRight
            sx={{
              width: 20,
              height: 20,
              color: "textWhite",
              transform: "translateX(-25%)",
              transition:
                "transform var(--durationOut) var(--animation-bezier)",

              "button:hover &": {
                transform: "translateX(0)",
                transitionDuration: "var(--durationIn)",
              },

              "path:first-of-type": {
                transform: "scaleX(0)",
                transformOrigin: "right",
                transition:
                  "transform var(--durationOut) var(--animation-bezier)",
              },

              "button:hover & path:first-of-type": {
                transform: "scaleX(1)",
                transitionDuration: "var(--durationIn)",
              },
            }}
          />
        </Box>
        <Text
          sx={{
            lineHeight: 1,
            pl: 4,
            pr: 5,
            textTransform: "uppercase",
            wordSpacing: "0.05em",
            fontSize: 3,
            fontWeight: 500,
            letterSpacing: "0.03em",
            gridColumn: ["2/3"],
            color: "text",
            transition: "color var(--durationOut) var(--animation-bezier)",
            position: "relative",
            whiteSpace: "nowrap",
            top: ["1px", 0],
            "::selection": {
              bg: "backgroundSelectionDark",
            },

            "button:hover &": {
              color: "textWhite",

              transitionDuration: "var(--durationIn)",
            },
          }}
        >
          {children}
        </Text>
      </Box>
    </ThemeUiButton>
  )
}
