import * as React from "react"
import { FC, ReactNode, MouseEvent } from "react"
import { SxStyleProp } from "theme-ui"
import { Container, Grid, Box } from "theme-ui"

export interface IPropsHero {
  alignImage: "left" | "right"
  children: ReactNode
  image: ReactNode
  imageSxOverride: SxStyleProp
  onMouseMove: (
    event: MouseEvent<HTMLDivElement, MouseEvent<Element, MouseEvent>>
  ) => void
  textSxOverride: SxStyleProp
}

export const Hero: FC<IPropsHero> = ({
  alignImage,
  children,
  image,
  imageSxOverride,
  onMouseMove,
  textSxOverride,
}) => {
  return (
    <Container
      as="header"
      onMouseMove={onMouseMove}
      sx={{
        bg: "backgroundPrimary",
        py: [14, null, 12, null, 14],
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid variant="base" sx={{ alignItems: [null, null, "center"] }}>
        <Box
          sx={{
            gridRow: ["1/2"],
            gridColumn:
              alignImage === "left"
                ? ["2/12", "3/11", "1/6"]
                : ["2/12", "3/11", "8/13", null, "7/13"],
            mb: [7, 10, 0],
            ...imageSxOverride,
          }}
        >
          {image}
        </Box>
        <Box
          sx={{
            gridColumn:
              alignImage === "left"
                ? ["span 12", null, "6/13", "6/12"]
                : ["span 12", null, "1/8", null, "1/7"],
            gridRow: [null, null, "1/2"],
            ...textSxOverride,
          }}
        >
          {children}
        </Box>
      </Grid>
    </Container>
  )
}
