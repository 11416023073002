import * as React from "react"
import { FC } from "react"
import { Divider, Heading, Text, Box } from "theme-ui"
import { useInView } from "react-intersection-observer"

import { AnimatedHeading } from "components/AnimatedHeading"
import { Orphans } from "components/Orphans"

export interface IPropsPrinciple {
  delay?: number
  headline: string[]
  paragraph: string
}

export const Principle: FC<IPropsPrinciple> = ({
  delay,
  headline,
  paragraph,
}) => {
  const [ref, inView, entry] = useInView({ threshold: 1 })
  const belowViewport = !inView && entry && entry.intersectionRect.top > 0

  return (
    <>
      <Divider
        sx={{
          gridColumn: "span 4",
          height: "1px",
          border: 0,
          mt: 0,
          bg: "divider",
        }}
      />
      <Box
        sx={{
          py: [10, null, 9, null, 11, 12],
        }}
      >
        <div ref={ref}>
          <Heading
            as="h2"
            sx={{
              color: "textWhite",
              gridColumn: "2/5",
              lineHeight: [1.3, 1.25, 1.2],
              mb: [4, 5, 6, null, null, 7],
              fontSize: [10, 11, 12, 13, 15, 17],
              letterSpacing: [null, null, "tight6"],
              "div ::selection": {
                bg: "backgroundSelectionDark",
              },
            }}
          >
            <Orphans>
              <AnimatedHeading
                headline={headline}
                visible={!belowViewport}
                delay={delay}
              />
            </Orphans>
          </Heading>
        </div>
        <Text
          sx={{
            color: "textWhite",
            fontSize: [4, 5, null, null, 7],
            lineHeight: 1.5,
            pr: ["10%", "5%"],
            "::selection": {
              bg: "backgroundSelectionDark",
            },
          }}
        >
          <Orphans>{paragraph}</Orphans>
        </Text>
      </Box>
    </>
  )
}
