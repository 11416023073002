import * as React from "react"
import { FC, ReactNode, useContext } from "react"
import { animated, useSpring, SpringValue } from "react-spring"
import { AspectRatio, Box } from "theme-ui"

export interface IPropsSquareAnimated {
  children: ReactNode
  className?: string
  springProps: {
    xyscale: SpringValue<number[]>
    opacity: SpringValue<number>
  }
}

const AnimatedBox = animated(Box)

export const SquareAnimated: FC<IPropsSquareAnimated> = ({
  children,
  className,
  springProps,
}) => {
  const { xyscale, opacity } = springProps

  return (
    <AnimatedBox
      style={{
        transform: xyscale.to(
          (x: number, y: number, scale: number) =>
            `rotateY(${x / 180}deg) rotateX(${-y / 180}deg) translate3d(${
              x / 30
            }px,${y / 30}px,0) scale(${scale})`
        ),
        opacity,
      }}
      className={className}
    >
      <AspectRatio
        ratio={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ height: "120%", width: "120%", flexShrink: 0 }}>
          <AnimatedBox
            style={{
              transform: xyscale.to(
                (x: number, y: number, _scale: number) =>
                  `translate3d(${x / 30}px,${y / 30}px,0)`
              ),
              filter: `grayscale(100%)`,
            }}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {children}
          </AnimatedBox>
        </Box>
      </AspectRatio>
    </AnimatedBox>
  )
}
