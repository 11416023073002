import * as React from "react"
import { FC, useContext, ReactNode } from "react"
import { Heading, Text } from "theme-ui"

import { ScrollContext } from "components/Layout"
import { AnimatedHeading } from "components/AnimatedHeading"
import { Orphans } from "components/Orphans"

export interface IPropsHeroTextLockup {
  children?: ReactNode
  className?: string
  headingArray: string[]
  paragraph: string
}

export const HeroTextLockup: FC<IPropsHeroTextLockup> = ({
  children,
  className,
  headingArray,
  paragraph,
}) => {
  const { scrolled } = useContext(ScrollContext)
  const longParagraph = paragraph.length > 200
  return (
    <div className={className}>
      <Heading
        as="h1"
        sx={{
          "div ::selection": {
            bg: "backgroundSelectionDark",
          },
          color: "textWhite",
          fontSize: [13, 15, 13, 14, 18, 20],
          lineHeight: [1.3, 1.2, null, null, 1.15],
          letterSpacing: "tight4",
          mb: [3, null, 4],
        }}
      >
        <AnimatedHeading
          headline={headingArray}
          visible={!scrolled}
          sx={{ pb: 1 }}
        />
      </Heading>
      <Text
        sx={{
          "::selection": {
            bg: "backgroundSelectionDark",
          },
          fontSize: longParagraph ? [4, 5, 3, 4, 5, 6] : [5, 6, 5, 6, 7, 8],
          color: "textWhite",
          lineHeight: 1.5,
          mb: [4, 5, null, null, null, 6],
          ":last-child": {
            mb: 0,
          },
        }}
      >
        <Orphans>{paragraph}</Orphans>
      </Text>
      {children}
    </div>
  )
}
