import { FC } from "react"
import * as React from "react"
import { Box } from "theme-ui"

import { Footer } from "components/Footer"
import { GridOverlay } from "components/GridOverlay"
import { HeroAbout } from "components/HeroAbout"
import { Layout } from "components/Layout"
import { Principles } from "components/Principles"
import { SEO } from "components/Seo"

export const AboutPage: FC = () => (
  <Layout>
    <GridOverlay />
    <SEO title="About Better’s tech team" location="/about" />
    <Box sx={{ bg: "backgroundPrimary" }}>
      <HeroAbout />
      <Principles />
    </Box>
    <Footer />
  </Layout>
)

export default AboutPage
