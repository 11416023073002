import * as React from "react"
import { FC } from "react"
import { Container, Grid } from "theme-ui"

import { Principle } from "components/Principle"

export const Principles: FC = () => {
  return (
    <Container
      sx={{
        pb: [14, null, null, null, 15, 16],
      }}
    >
      <Grid
        variant="base"
        as="ul"
        sx={{
          listStyleType: "none",
          alignItems: "baseline",
          li: {
            gridColumn: ["span 12", "span 6"],
          },
        }}
      >
        <li>
          <Principle
            headline={["210", "Engineers"]}
            paragraph={`Our tech team is still relatively small, and every new engineer has a significant opportunity at helping us shape the technical direction and culture.`}
          />
        </li>
        <li>
          <Principle
            headline={["Best Places", "to work"]}
            paragraph={`We’re one of Crain’s “Best Places to work” for 2 years running.`}
            delay={100}
          />
        </li>
        <li>
          <Principle
            headline={["100 deployments", "a day"]}
            paragraph={`We do continuous deployment and we ship code 50-100 times every day. Iteration speed is a competitive advantage in a legacy industry, and it’s something we deeply believe in.
          `}
          />
        </li>
        <li>
          <Principle
            headline={["Better", "technology"]}
            paragraph={`We do continuous deployment and we ship code 50-100 times every day.`}
            delay={100}
          />
        </li>
        <li>
          <Principle
            headline={[`$405 Millions`]}
            paragraph={` We’ve secured over $405 million from our investors to date.`}
          />
        </li>
        <li>
          <Principle
            headline={[`3x growth`]}
            paragraph={`The tech team has grown by more than 3x since last year, and we continue to grow.`}
            delay={100}
          />
        </li>
        <li>
          <Principle
            headline={["15 Trillion", "dollar market"]}
            paragraph={`Total value of US mortgages are currently 15 Trillion dollars. It’s one of the largest asset classes in the world, roughly as large as US equities.`}
          />
        </li>
        <li>
          <Principle
            headline={["7 million", "Americans"]}
            paragraph={`About seven million Americans get a mortgage every year. Yet, the average experience is comparable to getting a root canal.`}
            delay={100}
          />
        </li>
      </Grid>
    </Container>
  )
}
