/** @jsx jsx */
import { FC, useCallback, useContext } from "react"
import { jsx, Text } from "theme-ui"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import { useSpring } from "react-spring"

import { Button } from "components/Button"
import { Hero } from "components/Hero"
import { HeroTextLockup } from "components/HeroTextLockup"
import { ImageFade } from "components/ImageFade"
import { SquareAnimated } from "components/SquareAnimated"
import { ScrollContext } from "components/Layout"

export const HeroAbout: FC = () => {
  const [ref, inView, entry] = useInView({ threshold: 0 })
  const { scrolled } = useContext(ScrollContext)

  const scale = scrolled ? 0.9 : 1

  const [springProps, set] = useSpring(
    () => ({
      xyscale: [0, 0, scale],
      opacity: scrolled ? 0.9 : 1,
      from: {
        xyscale: [0, 0, scale],
        opacity: 0,
      },
      config: { mass: 10, tension: 550, friction: 140 },
    }),
    [scrolled]
  )

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ]

  const onMove = useCallback(
    ({ clientX: x, clientY: y }: { clientX: number; clientY: number }) =>
      void set({ xyscale: [...calc(x, y), scale] }),
    []
  )

  const data = useStaticQuery(graphql`
    query AboutImages {
      allImageSharp(
        filter: { original: { src: { regex: "/better-about/" } } }
      ) {
        edges {
          node {
            id
            original {
              width
              height
              src
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const { edges: images } = data.allImageSharp

  return (
    <Hero
      onMouseMove={onMove}
      alignImage="right"
      image={
        <div ref={ref}>
          <SquareAnimated
            springProps={springProps}
            sx={{
              boxShadow: "heroAbout",
              "div ::selection": {
                bg: "backgroundSelectionDark",
              },
            }}
          >
            <ImageFade images={images} pause={!inView} />
          </SquareAnimated>
        </div>
      }
    >
      <HeroTextLockup
        headingArray={["About Better’s", "tech team"]}
        paragraph={`We are one of the fastest-growing tech teams in NYC. Why? Because
        we’ve spent the past 5 years taking apart and rebuilding one of the
        most broken processes out there: buying a home. From automation to
        data analytics to security implementation, we’re moving faster than
        any other bank, lender, or tech start-up out there. And people are
        starting to take notice.`}
      />
      <Link
        to="/careers"
        sx={{
          color: "textWhite",
          display: "block",
          mt: "24px",
          ":focus-visible": {
            outline: "1px dashed",
          },
        }}
      >
        <Button>
          <Text sx={{ color: "textWhite" }}>View open positions</Text>
        </Button>
      </Link>
    </Hero>
  )
}
